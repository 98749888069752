<template>

  <div>

    <Verified></Verified>

  </div>

</template>
<script>
import Verified from '@/components/System/Client/Verified.vue'

export default {
  name: 'Views-System-Client-Verified',
  components: {
    Verified
  }
}
</script>

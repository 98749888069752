<template>

  <div>

    <SigninWithEmail></SigninWithEmail>

    <v-row class="mt-1">

      <v-col
        align="center"
        justify="center"
      >

        <a>

          <h5 class="font-weight-regular grey--text">Forgot your password?</h5>

        </a>

      </v-col>

    </v-row>

  </div>

</template>
<script>
import SigninWithEmail from '@/components/Authentication/User/Signin-With-Email.vue'

export default {
  name: 'View-Signin-With-Email',
  components: {
    SigninWithEmail
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.emails,"search":_vm.search,"items-per-page":20,"loading":_vm.loading,"item-key":"cntrl_no","show-select":"","height":"250px"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('span',[_vm._v("Mailing List")]),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.bounce_upload_btn,"large":"","icon":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-remove-outline")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Proceed? ")]),_c('v-card-text',[_vm._v("Invalid Email")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.debounce_invalid_email(), _vm.dialog = false}}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.bounce_upload_btn,"large":"","icon":""},on:{"click":_vm.debounce_upload}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-upload-outline")])],1)]}}])},[_c('span',[_vm._v("Upload")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true}]),model:{value:(_vm.validate),callback:function ($$v) {_vm.validate=$$v},expression:"validate"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_validator,"items":_vm.email_validator,"search":_vm.search_validator,"items-per-page":7,"loading":_vm.loading_validator,"item-key":"cntrl_no","height":"250px"},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.status === 'queued' || item.status === 'processing' || item.status === 'preparing')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.check_debounce_status(item.provider_id)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1):_vm._e(),(item.status === 'completed')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.debounce_extract_report(item)}}},[_c('v-icon',[_vm._v("mdi-database-import-outline")])],1):_vm._e(),(item.status === 'extracted')?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.debounce_translate(item)}}},[_c('v-icon',[_vm._v("mdi-clipboard-check-outline")])],1):_vm._e(),(item.status === 'validated')?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-lock-check-outline")])],1):_vm._e()]}}])})],1)],1),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar= false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
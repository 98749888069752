<template>

  <div>

    <Payslip></Payslip>

  </div>
</template>

<script>
import Payslip from '@/components/Download/Payslip.vue'

export default {
  name: 'View-Payslip',
  components: {
    Payslip
  },
  data () {
    return {
    }
  }
}
</script>

<template>

  <v-app>

    <v-app-bar
      app
      flat
      :color="color"
      outlined
    >
      <v-spacer />

        <!-- sigin home -->
        <v-btn
          class="text--secondary"
          medium
          text
          v-if="signup"
          @click="login()"
        >

          Sign In

        </v-btn>

        <span class="text --disabled" v-if="signup">|</span>

        <!-- signup -->
        <v-btn
          class="text--secondary"
          medium
          text
          v-if="signup"
          :to="{ name: 'Signup' }"
          disabled
        >

          Register your Company

        </v-btn>

        <!-- sign after create-->
        <v-btn
          class="mr-2 text--secondary"
          medium
          text
          v-else
          @click="login()"
        >

          Sign in

        </v-btn>

    </v-app-bar>

    <!-- root layout -->
    <v-main>

      <v-container fluid pa-0>

        <!-- <v-layout justify-center align-center> -->

        <!-- if using vue-router -->
        <router-view></router-view>

        <!-- </v-layout> -->

      </v-container>

    </v-main>

  </v-app>

</template>

<script>
import { mapGetters } from 'vuex'
// import axios from 'axios'

export default {
  name: 'App',
  data () {
    return {
      signup: true
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      loggedIn: 'loggedIn',
      systemLoggedIn: 'systemLoggedIn'
    }),
    color () {
      return this.$vuetify.theme.dark ? '#121212' : 'white'
    }
  },
  mounted () {
    if (this.$route.matched[0].meta.type === 'User') {
      // check if token exist
      if (this.loggedIn) {
        // retrieve authenticated user
        this.$store.dispatch('AUTH_USER', 'User')
          .then(() => {
            // is admin?
            this.$store.dispatch('AUTH_TYPE')
              .then(response => {
                if (response.data === 'F') {
                  this.$router.push({ name: 'Employee-Dashboard' }, () => {})
                } else {
                  this.$router.push({ name: 'Admin-Dashboard' }, () => {})
                }
              })
          })
      }

      if (this.systemLoggedIn) {
        // retrieve authenticated user
        this.$store.dispatch('AUTH_USER', 'System')
          .then(() => {
            this.$router.push({ name: 'System-Dashboard' })
          })
      }
    }

    if (this.$route.matched[0].meta.type === 'System') {
      // check if token exist
      if (this.loggedIn) {
        // retrieve authenticated user
        this.$store.dispatch('AUTH_USER', 'User')
          .then(() => {
            // is admin?
            this.$store.dispatch('AUTH_TYPE')
              .then(response => {
                if (response.data === 'F') {
                  this.$router.push({ name: 'Employee-Dashboard' }, () => {})
                } else {
                  this.$router.push({ name: 'Admin-Dashboard' }, () => {})
                }
              })
          })
      }

      if (this.systemLoggedIn) {
        // retrieve authenticated user
        this.$store.dispatch('AUTH_USER', 'System')
          .then(() => {
            this.$router.push({ name: 'System-Dashboard' })
          })
      }
    }

    if (this.$route.matched[0].meta.guard === 'User') {
      // check if token exist
      if (this.loggedIn) {
        // retrieve authenticated user
        this.$store.dispatch('AUTH_USER', 'User')
          .then(() => {
            // is admin?
            this.$store.dispatch('AUTH_TYPE')
          })
      }
    }
  },
  methods: {
    login () {
      this.$router.push({ name: 'Signin' }).catch(() => {})
      // console.log(localStorage.getItem('s_t'))
      // console.log(localStorage.getItem('u_t'))
      // if (this.$store.state.token !== '' || this.$store.state.systemToken !== null) {
      //   this.$router.push({ name: 'EntryPoint' })
      // } else {
      //   this.$router.push({ name: 'Signin' }).catch(() => {})
      // }
    }
  },
  created () {
    window.addEventListener('storage', function (event) {
      if (event.key === 'u_t' && event.oldValue !== event.newValue) {
        location.reload()
      }

      if (event.key === 's_t' && event.oldValue !== event.newValue) {
        location.reload()
      }
    })
    // this.mailgun_bounce()
  }
}
</script>

<template>

  <div>

    <PayrollGroup></PayrollGroup>

  </div>
</template>

<script>
import PayrollGroup from '@/components/Admin/Payroll/Payroll-Group.vue'

export default {
  name: 'View-Admin-Payroll-Group',
  components: {
    PayrollGroup
  }
}
</script>

<template>

  <div>

      <v-container v-if="show_success_msg===false">

        <v-card
          class="mx-auto my-auto mt-16"
          width="360"
          outlined
          :loading="loading"
          elevation="3"
        >

          <v-card-title>Enter New Password</v-card-title>

            <!-- <v-card-text> -->

              <v-container>

                <v-form
                  class="mt-n4"
                  ref="form"
                  v-model="formHasErrors"
                  lazy-validation
                >

                  <!-- new password field-->
                  <v-row class="mt-n4">

                    <v-col>

                      <v-text-field
                        v-model="form.new_password"
                        :append-icon="show_new ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show_new ? 'text' : 'password'"
                        @click:append="show_new = !show_new"
                        dense
                        label="Password"
                        :rules="rules.new_password"
                        required
                        outlined
                      />

                    </v-col>

                  </v-row>

                  <!-- login button -->

                  <v-card-actions>

                    <v-row class="mt-n4">

                      <v-col>

                        <v-btn
                          @click="submit"
                          block
                          medium
                          color="primary"
                          :disabled="!formHasErrors || btn_disabled"
                        >

                          Submit

                        </v-btn>

                      </v-col>

                    </v-row>

                  </v-card-actions>

                </v-form>

              </v-container>

            <!-- </v-card-text> -->

        </v-card>

      </v-container>

      <v-container v-else>
        <ResetPasswordMessage v-show="show_success_msg"></ResetPasswordMessage>
      </v-container>

      <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
      >

        {{ snackbarText }}

        <template v-slot:action="{ attrs }">

          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar= false "
          >

            Close

          </v-btn>

        </template>

      </v-snackbar>

  </div>

</template>
<script>
import axios from 'axios'
import ResetPasswordMessage from '@/components/Common/Reset-Password-Message.vue'

export default {
  name: 'Reset-Password',
  components: {
    ResetPasswordMessage
  },
  data () {
    return {
      rules: {
        required: value => !!value || 'Required.',
        new_password: [
          value => !!value || 'New Password is required'
        ]
        // verify_new_password: [
        //   value => !!value || 'Password is required'
        // ]

      },
      show_new: false,
      btn_disabled: false,
      formHasErrors: false,
      readonly: false,
      loading: false,
      show_success_msg: false,
      multiLine: true,
      snackbarText: '',
      snackbar: false,
      form: {
        email: '',
        new_password: ''
        // verify_new_password: ''
      }
    }
  },
  methods: {
    async submit () {
      this.loading = true
      this.btn_disabled = true
      try {
        await new Promise((resolve, reject) => {
          this.loading = true
          axios({
            url: 'auth/password/reset',
            method: 'POST',
            data: {
              token: this.$route.params.token,
              email: this.$route.params.email,
              password: this.form.new_password

            }
          })
            .then(response => {
              this.show_success_msg = response.data.message
              this.loading = false
              this.btn_disabled = true
              this.readonly = true
              resolve(response)
            })
            .catch(error => {
              reject(error)
              this.snackbar = true
              this.snackbarText = error.response.data.message
              this.loading = false
              this.btn_disabled = true
              this.readonly = false
            })
        })
      } catch (error) {
      }
    }
  }
  // computed: {
  //   passwordConfirmation () {
  //     return () =>
  //       this.form.new_password === this.form.verify_new_password || 'Password did not match.'
  //   }
  // }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    email: '',
    is_admin: '',
    result: '',
    log_type_to_send: '',
    log_type_with_errors: '',
    log_type_queued: '',
    log_type_delivered: '',
    token: localStorage.getItem('u_t') || '',
    systemToken: localStorage.getItem('s_t') || null,
    refresh_token: localStorage.getItem('u_rt') || '',
    debounce_balance: '',
    unknown_email: '',
    cacheToken: null,
    cacheSystemToken: null,
    emailVerified: false,
    auth_has_error: false,
    auth_message: ''
  },
  getters: {
    count_to_send (state) {
      return state.log_type_to_send
    },
    count_delivered (state) {
      return state.log_type_delivered
    },
    count_queued (state) {
      return state.log_type_queued
    },
    count_with_errors (state) {
      return state.log_type_with_errors
    },
    debounce_balance (state) {
      return state.debounce_balance
    },
    unknown_email (state) {
      return state.unknown_email
    },
    AUTHENTICATED_TYPE (state) {
      return state.is_admin
    },
    AUTHENTICATED_USER (state) {
      return state.user
    },
    AUTH_MESSAGE (state) {
      return state.auth_message
    },
    AUTH_HAS_ERROR (state) {
      return state.auth_has_error
    },
    loggedIn: state => !!state.token,
    systemLoggedIn (state) {
      return state.systemToken !== null
    },
    hasVerifiedEmail (state) {
      return state.emailVerified
    },
    email (state) {
      return state.email
    }
  },
  mutations: {
    set_count_to_send (state, payload) {
      state.log_type_to_send = payload
    },
    set_count_delivered (state, payload) {
      state.log_type_delivered = payload
    },
    set_count_queued (state, payload) {
      state.log_type_queued = payload
    },
    set_count_with_errors (state, payload) {
      state.log_type_with_errors = payload
    },
    set_debounce_balance (state, payload) {
      state.debounce_balance = payload
    },
    set_unknown_email (state, payload) {
      state.unknown_email = payload
    },
    SET_AUTHENTICATED_TYPE (state, payload) {
      state.is_admin = payload
    },
    SET_AUTHENTICATED_USER (state, payload) {
      state.user = payload
    },
    logout (state) {
      state.token = null
    },
    SET_AUTH_MESSAGE (state, payload) {
      state.auth_message = payload
    },
    SET_AUTH_HAS_ERROR (state, payload) {
      state.auth_has_error = payload
    },
    login (state, payload) {
      state.token = payload
      // state.cacheSystemToken = payload
    },
    SET_REFRESH_TOKEN (state, payload) {
      state.refresh_token = payload
    },
    systemLogout (state) {
      state.systemToken = null
    },
    systemLogin (state, payload) {
      state.systemToken = payload
      state.cacheSystemToken = payload
    },
    is_system_token_null (state) {
      state.systemToken = localStorage.getItem('s_t')
    },
    is_system_token_change (state) {
      if (state.cacheSystemToken !== state.systemToken) {
        state.systemToken = null
      }
    },
    hasVerifiedEmail (state, payload) {
      state.emailVerified = payload
    },
    email (state, payload) {
      state.email = payload
    }
  },
  actions: {
    async count_to_send (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/client/email/status', {
            params: {
              what_status: [125]
            }
          })
            .then(response => {
              context.commit('set_count_to_send', response.data.email_status)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async count_delivered (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/client/email/status', {
            params: {
              what_status: [123]
            }
          })
            .then(response => {
              context.commit('set_count_delivered', response.data.email_status)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async count_queued (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/client/email/status', {
            params: {
              what_status: [133]
            }
          })
            .then(response => {
              context.commit('set_count_queued', response.data.email_status)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async count_with_errors (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/client/email/status', {
            params: {
              what_status: [122, 124, 134, 135, 136, 137, 138, 145]
            }
          })
            .then(response => {
              context.commit('set_count_with_errors', response.data.email_status)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async debounce_balance (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/debounce/balance')
            .then(response => {
              context.commit('set_debounce_balance', response.data.balance)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async unknown_email (context) {
      try {
        return new Promise((resolve, reject) => {
          axios.get('s/dashboard/email/unknown')
            .then(response => {
              context.commit('set_unknown_email', response.data.unknown_email)
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    async AUTH_TYPE (context) {
      try {
        if (context.getters.loggedIn) {
          return new Promise((resolve, reject) => {
            axios.get('u/type')
              .then(response => {
                context.commit('SET_AUTHENTICATED_TYPE', response.data)
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      } catch (error) {
      }
    },
    async AUTH_USER (context, guard) {
      // user
      try {
        if (guard === 'User' && context.getters.loggedIn) {
          return new Promise((resolve, reject) => {
            axios.get('u/user')
              .then(response => {
                context.commit('SET_AUTHENTICATED_USER', response.data)
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      } catch (error) {
      }
      // system
      if (guard === 'System') {
        if (context.getters.systemLoggedIn) {
          await new Promise((resolve, reject) => {
            axios.get('s/user')
              .then(response => {
                context.commit('SET_AUTHENTICATED_USER', response.data)
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      }
    },
    async AUTH_USER_LOGOUT (context) {
      try {
        if (context.getters.loggedIn) {
          return new Promise((resolve, reject) => {
            axios.post('u/logout')
              .then(response => {
                localStorage.removeItem('u_t')
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      } catch (error) {
      }
    },
    // user token
    async AUTHENTICATE (context, payload) {
      context.commit('SET_AUTH_MESSAGE', '')
      context.commit('SET_AUTH_HAS_ERROR', false)
      try {
        await new Promise((resolve, reject) => {
          axios.post('u/login', {
            email: payload.email,
            password: payload.password
          })
            .then(response => {
              localStorage.setItem('u_t', response.data.access_token)
              localStorage.setItem('u_rt', response.data.refresh_token)
              context.commit('login', response.data.access_token)
              context.commit('SET_REFRESH_TOKEN', response.data.refresh_token)
              // context.dispatch('authenticatedUser', 'User')
              resolve(response)
            })
            .catch(error => {
              localStorage.removeItem('u_t')
              localStorage.removeItem('u_rt')
              context.commit('SET_AUTH_MESSAGE', error.response.data.message)
              context.commit('SET_AUTH_HAS_ERROR', true)
              reject(error)
            })
        })
      } catch (error) {
        localStorage.removeItem('u_t')
      }
    },
    async systemLogout (context) {
      try {
        if (context.getters.systemLoggedIn) {
          await new Promise((resolve, reject) => {
            axios.post('s/logout')
              .then(response => {
                localStorage.removeItem('s_t')
                this.state.user = {}
                context.commit('systemLogout')
                resolve(response)
              })
              .catch(error => {
                reject(error)
              })
          })
        }
      } catch (error) {
      }
    },
    // system token
    async systemLogin (context, payload) {
      context.commit('SET_AUTH_MESSAGE', null)
      try {
        await new Promise((resolve, reject) => {
          axios.post('s/login', {
            email: payload.email,
            password: payload.password
          })
            .then(response => {
              localStorage.setItem('s_t', response.data.access_token)
              context.commit('systemLogin', response.data.access_token)
              resolve(response)
            })
            .catch(error => {
              localStorage.removeItem('s_t')
              context.commit('SET_AUTH_MESSAGE', error.response.data.message)
              reject(error)
            })
        })
      } catch (error) {
        localStorage.removeItem('s_t')
      }
    },
    async hasVerifiedEmail (context, id) {
      try {
        await new Promise((resolve, reject) => {
          axios.get('auth/client/email', {
            params: {
              cntrl_no: id
            }
          })
            .then(response => {
              const emailVerified = response.data.email
              if (typeof (emailVerified) !== 'undefined') {
                context.commit('hasVerifiedEmail', true)
                context.commit('email', response.data.email)
              } else {
                context.commit('hasVerifiedEmail', false)
              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    }
  },
  modules: {
  }
})

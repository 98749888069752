<template>

  <div>

    <v-container>
      <!-- downloading -->
      <v-alert
        class="mx-auto mt-16"
        border="top"
        colored-border
        type="info"
        elevation="2"
        v-show="!error"
        v-if="downloading"
      >

        <h3>
          {{ message }}
        </h3>

      </v-alert>

      <!-- done -->
      <v-alert
        class="mx-auto mt-16"
        border="top"
        colored-border
        type="success"
        elevation="2"
        v-show="!error"
        v-else
      >

        <h3>
          {{ message }}
        </h3>

      </v-alert>

      <!-- error -->
      <v-alert
        class="mx-auto mt-16"
        border="top"
        colored-border
        type="error"
        elevation="2"
        v-if="error"
      >

        <h3>
          {{ message }}
        </h3>

      </v-alert>

    </v-container>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      message: 'Downloading Payslip....',
      downloading: true,
      error: false
    }
  },
  methods: {
    download_payslip () {
      axios({
        url: 'u/payslip/download',
        method: 'POST',
        responseType: 'blob',
        data: {
          file_id: this.$route.params.id
        }
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', this.$route.params.id + '.pdf')
          document.body.appendChild(fileLink)

          fileLink.click()
          this.downloading = false
          this.message = 'Done!'
        })
        .catch(() => {
          this.error = true
          this.message = 'Payslip not found.'
        })
    }
  },
  created () {
    this.download_payslip()
  }
}
</script>

<template>

  <div>

    <Profile></Profile>

  </div>
</template>

<script>
import Profile from '@/components/Profile/Profile.vue'

export default {
  name: 'View-Profile',
  components: {
    Profile
  },
  data () {
    return {
    }
  }
}
</script>

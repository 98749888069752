<template>

  <v-app>

    <!-- nav bar -->
    <v-navigation-drawer
      v-model="drawer"
      bottom
      app
    >

      <template v-slot:prepend>

        <v-skeleton-loader
          v-if="loading"
          type="list-item-avatar-two-line"
          class="py-1"
        />

        <v-list-item v-else two-line>

          <v-list-item-avatar>

            <v-img :src="link + (co_name_[0]) +'.svg'" />

            <!-- <v-img v-else :src="link + (user.co_name_[0]) +'.svg'" /> -->

          </v-list-item-avatar>

          <v-list-item-content>

            <!-- <v-list-item-title>{{ co_name_ }}</v-list-item-title> -->

            <v-list-item-subtitle>{{ co_sname }}</v-list-item-subtitle>

          </v-list-item-content>

        </v-list-item>

      </template>

      <v-divider></v-divider>

      <!-- lists -->

      <v-list
        dense
      >

        <!-- first list -->
        <v-list-item
          :to="{ name: 'System-Dashboard' }"
          link
        >

          <!-- icon -->
          <v-list-item-action>

            <v-icon>mdi-view-dashboard-outline</v-icon>

          </v-list-item-action>

          <!-- text -->
          <v-list-item-content>

            <v-list-item-title>Dashboard</v-list-item-title>

          </v-list-item-content>

        </v-list-item>
        <!-- <v-list-item
          :to="{ name: 'System-Dashboard' }"
          link
        > -->

          <!-- icon -->
          <!-- <v-list-item-action>

            <v-icon>mdi-email-outline</v-icon>

          </v-list-item-action> -->

          <!-- text -->
          <!-- <v-list-item-content>

            <v-list-item-title>Email Validation</v-list-item-title>

          </v-list-item-content>

        </v-list-item> -->

        <!-- ------------------------------------------------------ -->

        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :prepend-icon="item.action"
          no-action
          color="indigo"
        >

          <!-- ---------------------------------------------------- -->

          <!-- item -->
          <template v-slot:activator>

            <v-list-item-content>

              <v-list-item-title v-text="item.title"></v-list-item-title>

            </v-list-item-content>

          </template>
          <!-- ---------------------------------------------------- -->

          <!-- sub item -->
          <v-list-item
            v-for="sub in item.subs"
            :key="sub.title"
            :to="sub.url"
            link
          >

            <v-list-item-icon>

            <v-icon v-text="sub.action"></v-icon>

            </v-list-item-icon>

            <v-list-item-title v-text="sub.title"></v-list-item-title>

          </v-list-item>

        <!-- ------------------------------------------------------ -->

        </v-list-group>

      </v-list>

      <!-- profile -->
      <template v-slot:append>

        <!-- <v-navigation-drawer
          v-model="drawer"
        > -->

        <v-divider />

        <div class="px-3 py-3 d-flex">

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            top
            offset-y
          >

            <template v-slot:activator="{ on }">

              <v-btn
                :color="color"
                dark
                v-on="on"
                small
              >

                Account

              </v-btn>

            </template>

            <v-card>

              <v-system-bar
                dense
                height="10px"
              />

              <v-list dense>

                <v-list-item link :to="{ name: 'System-Profile', params: { username: user.email } }">

                  <v-list-item-avatar>

                    <v-img v-if="user.image_url !== null" :src="user.image_url" />

                    <v-img v-else :src="link + (user.frst_nme[0] + user.last_nme[0]) +'.svg'" />

                  </v-list-item-avatar>

                  <v-list-item-content>

                    <v-list-item-title>{{ user.frst_nme }} {{ user.last_nme }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    <!-- <v-list-item-subtitle v-if="user_num == 0">{{ user_id_ }} - Admin</v-list-item-subtitle> -->
                    <!-- <v-list-item-subtitle v-else>{{ user_id_ }}</v-list-item-subtitle> -->

                  </v-list-item-content>

                </v-list-item>

              </v-list>

              <v-divider />

              <v-list
                dense
              >

                <v-list-item
                  v-for="(account, item) in account"
                  :key="item"
                  :to="account.url"
                  link
                >

                    <v-list-item-icon>

                      <v-icon v-text="account.icon"></v-icon>

                    </v-list-item-icon>

                    <v-list-item-content>

                      <v-list-item-title v-text="account.text"></v-list-item-title>

                    </v-list-item-content>

                </v-list-item>

                <v-list-item
                  @click="signout()"
                >
                  <v-list-item-icon>

                    <v-icon>mdi-logout-variant</v-icon>

                  </v-list-item-icon>

                  <v-list-item-content>

                    <v-list-item-title>Sign Out</v-list-item-title>

                  </v-list-item-content>

                </v-list-item>

              </v-list>

            </v-card>

          </v-menu>

          <v-spacer />

          <v-btn
              icon
              small
              v-if="!$vuetify.theme.dark"
              @click="toggleTheme"
            >

              <v-icon>mdi-brightness-4</v-icon>

            </v-btn>

            <v-btn
              icon
              small
              v-if="$vuetify.theme.dark"
              @click="toggleTheme"
            >

              <v-icon>mdi-brightness-5</v-icon>

            </v-btn>

        </div>

        <!-- </v-navigation-drawer> -->

      </template>

    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      elevation="1"
      dense
      dark
    >

      <v-app-bar-nav-icon @click="drawer = !drawer" class="white--text"></v-app-bar-nav-icon>

      <v-toolbar-title class="white--text">System Dashboard</v-toolbar-title>

    </v-app-bar>

    <!-- <v-main> -->

      <v-container fluid>

        <v-container v-if="this.$route.name === 'System-Dashboard'">

          <v-row>

            <v-col cols="6" md="3" sm="4">
              <DebounceBalance />
            </v-col>

            <v-col cols="6" md="3" sm="4">
              <UnknownEmail />
            </v-col>

            <v-col cols="6" md="3" sm="4">
              <ToSendEmail />
            </v-col>

            <v-col cols="6" md="3" sm="4">
              <DeliveredEmail />
            </v-col>

            <v-col cols="6" md="3" sm="4">
              <QueuedEmail />
            </v-col>

            <v-col cols="6" md="3" sm="4">
              <EmailWithErrors />
            </v-col>

          </v-row>

        </v-container>

        <router-view> </router-view>

      </v-container>

    <!-- </v-main> -->

  </v-app>

</template>

<script>
import { mapGetters } from 'vuex'
import DebounceBalance from '@/components/Common/Dashboard/System/debounce-balance.vue'
import UnknownEmail from '@/components/Common/Dashboard/System/unknown-email.vue'
import ToSendEmail from '@/components/Common/Dashboard/System/to-send-email.vue'
import DeliveredEmail from '@/components/Common/Dashboard/System/delivered-email.vue'
import QueuedEmail from '@/components/Common/Dashboard/System/queued-email.vue'
import EmailWithErrors from '@/components/Common/Dashboard/System/with-errors.vue'

export default {
  name: 'System-Dashboard',

  components: {
    DebounceBalance,
    UnknownEmail,
    ToSendEmail,
    DeliveredEmail,
    QueuedEmail,
    EmailWithErrors
  },

  data () {
    return {
      drawer: null,
      menu: false,
      loading: false,
      username: 'Joan Visto',
      co_name_: 'Virtual Logic Inc.',
      co_sname: 'VLI',
      link: 'https://avatars.dicebear.com/api/initials/',
      images: {
        company: require('@/assets/discord.png'),
        profile: require('@/assets/discord.png')
      },
      items: [
        {
          action: 'mdi-account-group-outline',
          title: 'Client',
          active: true,
          subs: [
            {
              action: 'mdi-email-check-outline',
              title: 'Verified',
              url: { name: 'System-Client-Verified' }
            },
            {
              action: 'mdi-account-check-outline',
              title: 'Approved',
              url: { name: 'System-Client-Approved' }
            },
            {
              action: 'mdi-email-alert-outline',
              title: 'Pending',
              url: { name: 'System-Client-Pending' }
            }
          ]
        },
        {
          action: 'mdi-email-outline',
          title: 'Email Validator',
          subs: [
            {
              action: 'mdi-email-alert-outline',
              title: 'Debounce',
              url: { name: 'System-Debounce-Validator' }
            },
            {
              action: 'mdi-email-alert-outline',
              title: 'Debounce V2',
              url: { name: 'System-Debounce-Validator-V2' }
            },
            {
              action: 'mdi-email-alert-outline',
              title: 'Export V2',
              url: { name: 'System-Debounce-Validator-Export-V2' }
            }
          ]
        }
      ],
      account: [
        { text: 'Settings', icon: 'mdi-cog-outline' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'AUTHENTICATED_USER'
    }),
    color () {
      return this.$vuetify.theme.dark ? '#121212' : 'primary'
    }
  },
  mounted () {
    this.$store.dispatch('AUTH_USER', 'System')
    this.$root.$on('newProfileImage', (payload) => {
      this.user.image_url = payload
    })
    this.$store.dispatch('debounce_balance')
    this.$store.dispatch('unknown_email')
    this.$store.dispatch('count_to_send')
    this.$store.dispatch('count_delivered')
    this.$store.dispatch('count_queued')
    this.$store.dispatch('count_with_errors')
  },
  methods: {
    signout () {
      this.$store.dispatch('systemLogout')
        .then(() => {
          this.$router.push({ name: 'System-Signin' })
        })
    },
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
    }
  },
  created () {
  }
}
</script>

<template>

  <div>

    <v-data-table
      :headers="headers"
      :items="masterfile"
      :search="search"
      :items-per-page="7"
      :loading="loading"
      class="elevation-1"
      height="400px"
      item-text="descript"
      item-value="id"
    >

      <template v-slot:top>

        <v-card-title>
          <span>Master Files</span>

          <v-spacer></v-spacer>

          <v-col cols="6">

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              hide-details
              dense
            />

          </v-col>

        </v-card-title>

      </template>

      <template v-slot:item.allow_email="{ item }">

        <v-edit-dialog>

          {{ build_allows(item.allow_email) }}

        </v-edit-dialog>

      </template>

      <template v-slot:item.allow_sms="{ item }">

        <v-edit-dialog>

          {{ build_allows(item.allow_sms) }}

        </v-edit-dialog>

      </template>

      <template v-slot:item.action="{ item }">

        <v-btn
          icon
          :to="{ name: 'Admin-MasterFile-Edit', params: { id: item.vli_empl_mst } }"
        >

          <v-icon>

            mdi-pencil-box-outline

          </v-icon>

        </v-btn>

      </template>

    </v-data-table>

    <v-snackbar
      v-model="snack"
      :timeout="3000"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          text
          @click="snack = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'Masterfile',
  data () {
    return {
      loading: false,
      search: '',
      snack: false,
      snackColor: '',
      snackText: '',
      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},

      headers: [
        {
          text: 'Employee #',
          value: 'empl_cde',
          sortable: true,
          width: '13%'
        },
        { text: 'Last Name', value: 'last_nme', width: '13%', sortable: true },
        { text: 'First Name', value: 'frst_nme', width: '13%', sortable: true },
        { text: 'Email', value: 'email', width: '13%', sortable: true },
        { text: 'Mobile Number', value: 'mobile__', width: '13%' },
        { text: 'Send Email', value: 'allow_email', width: '13%' },
        { text: 'Send SMS', value: 'allow_sms', width: '7%' },
        { text: 'Action', value: 'action', width: '13%', sortable: false }
      ],

      allow_email: [
        {
          id: 'T',
          descript: 'Yes'
        },
        {
          id: 'F',
          descript: 'No'
        }
      ],

      masterfile: []
    }
  },
  methods: {
    async load_masterfile () {
      try {
        await new Promise((resolve, reject) => {
          this.loading = true
          axios.get('u/maintenance/masterfile')
            .then(response => {
              this.masterfile = response.data
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    build_allows (item) {
      switch (item) {
        case 'T':
          return 'Yes'
        case 'F':
          return 'No'
      }
    }
  },
  created () {
    this.load_masterfile()
  }
}
</script>

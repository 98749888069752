<template>

  <div>

    <Pending></Pending>

  </div>

</template>
<script>
import Pending from '@/components/System/Client/Pending.vue'

export default {
  name: 'Views-System-Client-Pending',
  components: {
    Pending
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.masterfile,"search":_vm.search,"items-per-page":7,"loading":_vm.loading,"height":"400px","item-text":"descript","item-value":"id"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[_c('span',[_vm._v("Master Files")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.allow_email",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',[_vm._v(" "+_vm._s(_vm.build_allows(item.allow_email))+" ")])]}},{key:"item.allow_sms",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',[_vm._v(" "+_vm._s(_vm.build_allows(item.allow_sms))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{ name: 'Admin-MasterFile-Edit', params: { id: item.vli_empl_mst } }}},[_c('v-icon',[_vm._v(" mdi-pencil-box-outline ")])],1)]}}])}),_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snack = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snack),callback:function ($$v) {_vm.snack=$$v},expression:"snack"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <div>

    <v-data-table
      :headers="headers"
      :items="archived"
      :search="search"
      :items-per-page="7"
      :loading="loading"
      class="elevation-1"
      height="400px"
    >

      <template v-slot:top>

        <v-card-title>
          <span>Archived Directories</span>

          <v-spacer></v-spacer>

          <v-col cols="6">

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              hide-details
              dense
            />

          </v-col>

        </v-card-title>

      </template>

      <template v-slot:item.payr_dir="{ item }">

        {{ item.payr_dir }} (Part  {{ item.part____ }})

      </template>

      <template v-slot:item.action="{ item }">

        <v-btn
          icon
          @click="download_payslip(item)"
          :disabled="disable_download"
        >

          <v-icon>

            mdi-file-download-outline

          </v-icon>

        </v-btn>

      </template>

    </v-data-table>

    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar= false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'EmployeeArchived',
  data () {
    return {
      loading: false,
      search: '',
      snackbar: false,
      disable_download: false,
      multiLine: true,
      snackbarText: '',
      max25chars: v => v.length <= 25 || 'Input too long!',
      pagination: {},

      headers: [
        {
          text: 'ID',
          value: 'payr_dir',
          sortable: true,
          width: '7%'
        },
        { text: 'Start Date', value: 'strt_dte', width: '7%', sortable: true },
        { text: 'End Date', value: 'last_dte', width: '7%' },
        { text: 'Gross Pay', value: 'grosspay', width: '7%' },
        { text: 'Deductions', value: 'deductn_', width: '7%' },
        { text: 'Net Pay', value: 'net_pay_', width: '7%' },
        { text: 'Credit Date', value: 'credt_dt', width: '7%' },
        { text: 'Action', value: 'action', width: '7%', sortable: false }

      ],

      archived: []
    }
  },
  methods: {
    async load_archived () {
      try {
        await new Promise((resolve, reject) => {
          this.loading = true
          axios.get('u/employee/archived/directories')
            .then(response => {
              this.archived = response.data
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    },
    download_payslip (item) {
      this.disable_download = true
      this.snackbar = true
      this.snackbarText = 'Downloading payslip...'
      axios({
        url: 'u/payslip/download',
        method: 'POST',
        responseType: 'blob',
        data: {
          vli_payslip_storage: item.vli_payslip_storage
        }
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', item.credt_dt + '.pdf')
          document.body.appendChild(fileLink)

          fileLink.click()
          this.disable_download = false
          this.snackbar = true
          this.snackbarText = 'Done!'
        })
        .catch(() => {
          this.disable_download = false
          this.snackbar = true
          this.snackbarText = 'Payslip not found.'
        })
    }
  },
  created () {
    this.load_archived()
  }
}
</script>

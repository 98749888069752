<template>

  <div>

    <v-data-table
      :headers="headers"
      :items="payroll"
      :search="search"
      :items-per-page="5"
      :loading="loading"
      class="elevation-1"
      height="400px"
    >

      <template v-slot:top>

        <v-card-title>

          <span v-if="show_id">Payroll Headers #{{ payroll[0].vli_payr_dir }} ({{ payroll[0].payr_dir }} - Part {{ payroll[0].part____ }})</span>

          <v-spacer></v-spacer>

          <!-- refresh -->
          <v-tooltip bottom>

            <template v-slot:activator="{ on, attrs }">

              <v-btn
                @click="payroll_header"
                v-bind="attrs"
                v-on="on"
                icon
                large
              >

                <v-icon>

                  mdi-file-refresh-outline

                </v-icon>

              </v-btn>

            </template>

            <span>Refresh</span>

          </v-tooltip>

          <v-col cols="4">

            <!-- search -->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              hide-details
              dense
            />

          </v-col>

        </v-card-title>

        <v-card-subtitle class="mt-n7">

          <span v-if="show_id">Start Date: {{ payroll[0].strt_dte }} | End Date: {{ payroll[0].last_dte }} | Credit Date: {{ payroll[0].credt_dt }}</span>

        </v-card-subtitle>

      </template>

      <!-- employee # -->
      <template v-slot:item.empl_cde="{ item }">

        <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">

              <td v-bind="attrs" v-on="on"><u>{{ item.empl_cde }}</u></td>

          </template>

          <span>System ID: {{ item.vli_empl_mst }}</span><br>
          <span>Email: {{ item.email }}</span><br>
          <span>Mobile: {{ item.mobile__ }}</span><br>
          <span>PDF Pin: {{ item.pdf_password }}</span>

          <!-- <span style="color:black">System ID: {{ item.vli_empl_mst }}</span><br>
          <span style="color:black">Email: {{ item.email }}</span><br>
          <span style="color:black">Mobile: {{ item.mobile__ }}</span> -->

        </v-tooltip>

      </template>

      <!-- grosspay -->
      <template v-slot:item.grosspay="{ item }">

        {{ formatNumber(item.grosspay) }}

      </template>

      <!-- deduction -->
      <template v-slot:item.deductn_="{ item }">

        {{ formatNumber(item.deductn_) }}

      </template>

      <!-- netpay -->
      <template v-slot:item.net_pay_="{ item }">

        {{ formatNumber(item.net_pay_) }}

      </template>

      <template v-slot:item.action="{ item }">

        <!-- view masterfile -->
        <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">

            <v-btn
              :to="{ name: 'Admin-MasterFile-Edit', params: { id: item.vli_empl_mst } }"
              icon
              v-bind="attrs"
              v-on="on"
            >

              <v-icon>

                mdi-file-account-outline

              </v-icon>

            </v-btn>

          </template>

          <span>View Information</span>

        </v-tooltip>

        <!-- download payslip -->
        <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">

            <v-btn
              @click="download_payslip(item)"
              icon
              :disabled="disable_download"
              v-bind="attrs"
              v-on="on"
            >

              <v-icon>

                mdi-file-download-outline

              </v-icon>

            </v-btn>

          </template>

          <span>Download Payslip</span>

        </v-tooltip>

        <!-- to send -->
        <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">

            <v-btn
              @click="send_payslip(item)"
              v-if="item.log_type === 125"
              icon
              v-bind="attrs"
              v-on="on"
            >

              <v-icon>

                mdi-email-send-outline

              </v-icon>

            </v-btn>

            <!-- resend -->
            <v-btn
              @click="editItem(item)"
              icon
              v-else
              v-bind="attrs"
              v-on="on"
            >

              <v-icon>

                mdi-file-send-outline

              </v-icon>

            </v-btn>

            <!-- dialog message -->
            <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
              :retain-focus="retainFocus"
            >

              <v-card>

                <v-card-title class="headline">Resend Payslip?</v-card-title>

                  <v-card-text>You are about to send payslip email(s) to {{ editedItem.last_nme }}, {{ editedItem.frst_nme }}. Proceed?</v-card-text>

                <v-card-actions>

                  <v-spacer></v-spacer>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="close"
                  >
                    No
                  </v-btn>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="send_payslip(editedItem)"
                  >
                    Yes
                  </v-btn>

                </v-card-actions>

              </v-card>

            </v-dialog>

          </template>

          <span>Send/Resend Payslip</span>

        </v-tooltip>

      </template>

    </v-data-table>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">

        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar= false"
        >

          Close

        </v-btn>

      </template>

    </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import { numberSeparator } from '@/util/common'

export default {
  name: 'Directory-Details',
  data () {
    return {
      loading: false,
      show_app_bar: true,
      multiLine: true,
      snackbar: false,
      dialog: false,
      hasErrors: false,
      show_id: false,
      retainFocus: false,
      disable_download: false,
      snackbarText: '',
      search: '',
      editedIndex: -1,
      editedItem: {
        vli_empl_mst: '',
        last_nme: '',
        frst_nme: ''
      },
      defaultItem: {
        vli_empl_mst: '',
        last_nme: '',
        frst_nme: ''
      },
      headers: [
        {
          text: 'Employee #',
          align: 'left',
          sortable: true,
          value: 'empl_cde'
        },
        { text: 'Last Name', value: 'last_nme', sortable: true },
        { text: 'First Name', value: 'frst_nme', sortable: true },
        { text: 'Gross Pay', value: 'grosspay', sortable: true },
        { text: 'Deduction', value: 'deductn_', sortable: true },
        { text: 'Net Pay', value: 'net_pay_', sortable: true },
        { text: 'Status', value: 'log_descript', align: 'center', sortable: true },
        { text: 'Action', value: 'action', align: 'center', sortable: false }
      ],
      payroll: []
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      user: 'AUTHENTICATED_USER'
    })
  },
  methods: {
    payroll_header () {
      this.loading = true
      axios.get('u/payroll/header', {
        params: {
          vli_payr_dir: this.$route.params.id
        }
      })
        .then(reponse => {
          this.payroll = reponse.data
          this.loading = false
          this.show_id = true
        })
    },
    formatNumber (num) {
      return numberSeparator(num)
    },
    editItem (item) {
      this.editedIndex = this.payroll.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    download_payslip (item) {
      this.disable_download = true
      this.snackbar = true
      this.snackbarText = 'Downloading payslip...'
      axios({
        url: 'u/payslip/download',
        method: 'POST',
        responseType: 'blob',
        data: {
          vli_payslip_storage: item.vli_payslip_storage
        }
      })
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', item.frst_nme + ' ' + item.last_nme + '.pdf')
          document.body.appendChild(fileLink)

          fileLink.click()
          this.disable_download = false
          this.snackbar = true
          this.snackbarText = 'Done!'
        })
        .catch(() => {
          this.disable_download = false
          this.snackbar = true
          this.snackbarText = 'Payslip not found.'
        })
    },
    send_payslip (item) {
      // single endpoint only
      this.dialog = false
      //       axios.get('u/payroll/send/payslip/employee', {
      //   params: {
      //     vli_empl_mst: item.vli_empl_mst,
      //     vli_payr_dir: this.$route.params.id
      //   }
      // })
      // axios({
      //   url: 'u/send/sms/payslip',
      //   method: 'POST',
      //   data: {
      //     mobile_number: item.mobile__
      //   }
      // })
      axios({
        url: 'u/payslip/send',
        method: 'POST',
        data: {
          type: 'single',
          vli_empl_mst: item.vli_empl_mst,
          vli_payr_dir: this.$route.params.id
        }
      })
        .then(response => {
          this.close()
          this.snackbar = true
          this.snackbarText = 'Payslip sending is now in process.'
          this.btn_disabled = true
          this.payroll_header()
        })
        .catch(error => {
          this.close()
          this.snackbar = true
          this.snackbarText = error.response.data.message
          this.payroll_header()
        })
    }
  },
  created () {
    this.payroll_header()
  }
}
</script>
<style>
/* styles */
u {
    border-bottom: 2px dotted #808080;
    text-decoration: none;
}
</style>

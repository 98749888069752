<template>

  <div>

    <Verified></Verified>

  </div>
</template>

<script>
import Verified from '@/components/Admin/Email/Verified.vue'

export default {
  name: 'View-Admin-Email-Verified',
  components: {
    Verified
  },
  data () {
    return {

    }
  }
}
</script>

<template>

  <div>

    <v-container v-if="show_verification_msg===false">

      <v-card
        class="mx-auto my-auto mt-16"
        width="360"
        outlined
        :loading="loading"
        elevation="3"
      >

        <v-card-title>Enter Your Email</v-card-title>

          <!-- <v-card-text> -->

            <v-container>

              <v-form
                ref="form"
                v-model="formHasErrors"
              >

                <!-- email -->
                <v-text-field
                  type="email"
                  v-model="form.email"
                  :rules="rules.email"
                  :readonly="readonly"
                  required
                  dense
                  outlined
                  label="Email"
                  @keydown.enter="submit"
              />

                <!-- button -->
                <v-card-actions>

                  <v-btn
                    @click="submit"
                    block
                    medium
                    color="primary"
                    :disabled="!formHasErrors || btn_disabled"
                  >

                    Send Reset Link

                  </v-btn>

                </v-card-actions>

              </v-form>

            </v-container>

          <!-- </v-card-text> -->

      </v-card>

    </v-container>

    <v-container v-else>

      <ForgotPasswordMessage :email="form.email" v-show="show_verification_msg"></ForgotPasswordMessage>

    </v-container>

  <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
      >

        {{ snackbarText }}

        <template v-slot:action="{ attrs }">

          <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar= false"
          >

            Close

          </v-btn>

        </template>

      </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'
import ForgotPasswordMessage from '@/components/Common/Forgot-Password-Message.vue'

export default {
  name: 'Forgot-Password-Email',
  components: {
    ForgotPasswordMessage
  },
  data () {
    return {
      form: {
        email: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        email: [
          value => !!value || 'Email is required',
          value => /.+@.+\..+/.test(value) || 'E-mail must be valid'
        ]

      },
      snackbarText: '',
      loading: false,
      multiLine: true,
      snackbar: false,
      formHasErrors: false,
      btn_disabled: false,
      show_verification_msg: false,
      readonly: false
    }
  },
  methods: {
    async submit () {
      this.loading = true
      this.btn_disabled = true
      try {
        await new Promise((resolve, reject) => {
          this.loading = true
          axios({
            url: 'auth/password/forgot',
            method: 'POST',
            data: {
              email: this.form.email
            }
          })
            .then(response => {
              this.show_verification_msg = true
              this.loading = false
              this.btn_disabled = true
              this.readonly = true
              resolve(response)
            })
            .catch(error => {
              reject(error)
              this.snackbar = true
              this.snackbarText = error.response.data.message
              this.loading = false
              this.btn_disabled = false
              this.readonly = false
            })
        })
      } catch (error) {
      }
    }
  }
}
</script>

<template>

  <div>

    <PayrollHeader></PayrollHeader>

  </div>
</template>

<script>
import PayrollHeader from '@/components/Admin/Payroll/Payroll-Header.vue'

export default {
  name: 'View-Admin-Payroll-Header',
  components: {
    PayrollHeader
  }
}
</script>

<template>

  <div>

    <v-card
      width="600px"
      class="mx-auto"
    >

      <v-card-title>Services</v-card-title>

      <v-divider></v-divider>

      <!-- <v-container> -->

        <v-sheet
          :color="color"
          rounded
          elevation="2"
          class="mx-auto"
        >
          <v-card-text>

            <!-- Allow Email-->
            <v-row>

              <v-col>

              <v-select
                  v-model="allow_email"
                  :items="allow_items"
                  label="Send Email"
                  item-text="descript"
                  item-value="id"
                  dense
                  outlined
                  :readonly="true"
                />

              </v-col>
            </v-row>

              <!-- Allow SMS-->
            <v-row>

              <v-col>

                <v-select
                  class="mt-n5"
                  v-model="allow_sms"
                  :items="allow_items"
                  label="Send SMS"
                  item-text="descript"
                  item-value="id"
                  dense
                  outlined
                  :readonly="true"
                />

              </v-col>

            </v-row>

            <v-card-actions>

              <v-row>

                <v-col class="text-right">

                  <v-btn class="primary" disabled>

                    Save

                  </v-btn>

                </v-col>

              </v-row>

            </v-card-actions>

          </v-card-text>

        </v-sheet>

        <!-- <v-divider class="mt-8"></v-divider> -->

        <!-- <v-btn class="primary mt-5">

          Change Password

        </v-btn> -->

      <!-- </v-container> -->

    </v-card>

    <!-- <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">

        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar= false"
        >

          Close

        </v-btn>

      </template>

    </v-snackbar> -->

  </div>

</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'AdminSubscriptionServices',
  data () {
    return {
      // saving: false,
      // saved: false,
      // multiLine: true,
      // snackbar: false,
      // snackbarText: '',
      allow_email: '',
      allow_sms: '',
      allow_items: [
        {
          id: 'T',
          descript: 'Yes'
        },
        {
          id: 'F',
          descript: 'No'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      user: 'AUTHENTICATED_USER'
    }),
    color () {
      return this.$vuetify.theme.dark ? '#121212' : 'white'
    }
  },
  methods: {
    subscription_services () {
      axios.get('u/subscription/services', {
        // allow_email: this.form.allow_email,
        // allow_sms: this.form.allow_sms
      })
        .then(response => {
          this.allow_email = response.data[0].allow_email
          this.allow_sms = response.data[0].allow_sms
        })
    }
  },
  created () {
    this.subscription_services()
  }
}
</script>

<template>

  <div>

    <Signup></Signup>

  </div>

</template>
<script>
import Signup from '@/components/Authentication/User/Signup.vue'

export default {
  name: 'View-Signup',
  components: {
    Signup
  }
}
</script>

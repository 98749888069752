<template>

  <div>

    <NotVerified></NotVerified>

  </div>
</template>

<script>
import NotVerified from '@/components/Admin/Email/Not-Verified.vue'

export default {
  name: 'View-Admin-Email-Not-Verify',
  components: {
    NotVerified
  },
  data () {
    return {

    }
  }
}
</script>

<template>

  <div>

    <v-data-table
      :headers="headers"
      :items="bounce"
      :search="search"
      :items-per-page="7"
      :loading="loading"
      class="elevation-1"
      height="400px"
    >

      <template v-slot:top>

        <v-card-title>
          <span>Bounces</span>

          <v-spacer></v-spacer>

          <v-col cols="6">

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              outlined
              hide-details
              dense
            />

          </v-col>

        </v-card-title>

      </template>

      <!-- <template v-slot:item.action="{ item }">

      <v-tooltip bottom>

          <template v-slot:activator="{ on, attrs }">

            <v-btn
              :to="{ name: 'Admin-Suppression-MasterFile-Edit', params: { id: item.vli_empl_mst } }"
              icon
              v-bind="attrs"
              v-on="on"
            >

              <v-icon>

                mdi-file-account-outline

              </v-icon>

            </v-btn>

          </template>

          <span>Edit Email Address</span>

        </v-tooltip>

      </template> -->

    </v-data-table>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'AdminEmailBounce',
  data () {
    return {
      loading: false,
      search: '',
      pagination: {},

      headers: [
        { text: 'Employee #', value: 'empl_cde', sortable: true },
        { text: 'Last Name', value: 'last_nme', sortable: true },
        { text: 'First Name', value: 'frst_nme', sortable: true },
        { text: 'Email', value: 'email', sortable: true },
        { text: 'Reason', value: 'reason', sortable: true },
        { text: 'Created at', value: 'created_at', sortable: true }
        // { text: 'Action', value: 'action', align: 'center' }

      ],

      bounce: []
    }
  },
  methods: {
    async load_bounce () {
      try {
        await new Promise((resolve, reject) => {
          this.loading = true
          axios.get('u/email/validator/report/debounce')
            .then(response => {
              this.bounce = response.data
              this.loading = false
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
      } catch (error) {
      }
    }
  },
  created () {
    this.load_bounce()
  }
}
</script>

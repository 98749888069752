<template>

  <div>

    <PayrollHistory></PayrollHistory>

  </div>

</template>
<script>
import PayrollHistory from '@/components/Employee/Payroll/History.vue'

export default {
  name: 'View-PayrollHistory',
  components: {
    PayrollHistory
  }
}
</script>

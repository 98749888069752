<template>

  <div>

    <Approved></Approved>

  </div>

</template>
<script>
import Approved from '@/components/System/Client/Approved.vue'

export default {
  name: 'Views-System-Client-Approved',
  components: {
    Approved
  }
}
</script>

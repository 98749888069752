<template>

  <div>

    <Services></Services>

  </div>
</template>

<script>
import Services from '@/components/Admin/Subscription/Services.vue'

export default {
  name: 'View-Admin-Subscription-Services',
  components: {
    Services
  },
  data () {
    return {
    }
  }
}
</script>

<template>

  <div>

    <Signin></Signin>

  </div>

</template>
<script>
import Signin from '@/components/Authentication/System/Signin.vue'

export default {
  name: 'View-System-Signin',
  components: {
    Signin
  }
}
</script>

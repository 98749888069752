<template>

  <v-card
    max-width="205"
  >
      <v-card-text class="justify-end mb-n4" style="font-size:1.0em" >
        To Send Email(s)
      </v-card-text>

      <v-card-text>
          <div class ="text-end font-weight-bold" style="font-size:1.0em">
            {{ to_send }}
          </div>
      </v-card-text>

  </v-card>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'to-send-email',
  computed: {
    ...mapGetters({
      to_send: 'count_to_send'
    })
  }
}
</script>

<template>

  <div>

    <v-system-bar
      app
      light
      window
      v-if="system_bar"
    >

      <span class="font-weight-bold">Please note that only 6 payroll directories will only be displayed. Directories that are not shown can still be viewed using the search bar.</span>

      <v-spacer></v-spacer>

      <v-btn
        small
        color="grey lighten-3"
        @click="accept"
      >

        Accept

      </v-btn>
    </v-system-bar>

    <v-expansion-panels >

      <v-app-bar
        dense
        :color="color"
        v-if="show_app_bar"
      >

        <v-app-bar-title>Payroll Directory</v-app-bar-title>

        <v-spacer></v-spacer>

        <v-col cols="4">

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search Payroll Directory #"
            outlined
            dense
            hide-details
            @keydown.enter="searchPayrollDirectory"
          />

        </v-col>

      </v-app-bar>

      <v-expansion-panel v-for="directory in directories" :key="directory.cntrl_no" multiple>

        <!-- <v-expansion-panel-header v-slot="{ open }" @click="retrieve_other_info(directory.cntrl_no)"> -->
        <v-expansion-panel-header v-slot="{ open }">
          <v-row>
            <v-col cols="12" md="4">
              ID: {{ directory.cntrl_no }} ({{ directory.payr_dir }} - Part  {{ directory.part____ }}) <span v-if="directory.disabled === 'F'"><v-icon color="teal">mdi-check</v-icon></span><span v-else><v-icon color="red">mdi-close</v-icon>Error in Posting to Web</span>
            </v-col>
            <v-col
              cols="12"
              class="text--secondary"
            >
              <v-fade-transition leave-absolute>
                <span v-if="open"></span>
                <v-row
                  v-else
                  no-gutter
                >
                  <v-col cols="12" md="4">
                    Start Date: {{ directory.strt_dte || 'Not set' }}
                  </v-col>
                  <v-col cols="12" md="4">
                    Last Date: {{ directory.last_dte || 'Not set' }}
                  </v-col>
                  <v-col cols="12" md="4">
                    Credit Date: {{ directory.credt_dt || 'Not set' }}
                  </v-col>
                </v-row>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="directory.strt_dte"
                label="Start Date"
                outlined
                dense
                readonly
              >

              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="directory.last_dte"
                label="Last Date"
                outlined
                dense
                readonly
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="directory.credt_dt"
                label="Credit Date"
                outlined
                dense
                readonly
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="directory.remarks_"
                label="Remarks"
                outlined
                dense
                readonly
              >

              </v-text-field>
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="12" md="3">

              <v-text-field
                :value="formatNumber(directory.grosspay)"
                label="Total Gross Pay"
                outlined
                dense
                :readonly="true"
              >

              </v-text-field>

            </v-col>

            <v-col cols="12" md="3">

              <v-text-field
                :value="formatNumber(directory.deduction)"
                label="Total Deductions"
                outlined
                dense
                readonly
              >

              </v-text-field>

            </v-col>

            <v-col cols="12" md="3">

              <v-text-field
                :value="formatNumber(directory.netpay)"
                label="Total Net Pay"
                outlined
                dense
                readonly
              >

              </v-text-field>

            </v-col>

            <v-col cols="12" md="3">

              <v-text-field
                v-model="directory.count"
                label="Number of Employees"
                outlined
                dense
                readonly
              >

              </v-text-field>

            </v-col>

          </v-row>

          <v-row class="mt-n5">

            <v-col>

              <v-btn
                medium
                class="mr-2"
                :disabled="directory.disabled === 'T'"
                :to="{ name: 'Admin-Payroll-Header', params: { id: directory.cntrl_no } }"
              >

                View

              </v-btn>

              <v-btn
                @click="count_tosend_status(directory.cntrl_no)"
                :disabled="directory.disabled === 'T'"
                medium
              >

                Send Payslip

              </v-btn>

            </v-col>

          </v-row>

          <!-- dialog message -->
          <v-row justify="center">

            <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
            >

              <v-card>

                <v-card-title class="headline">Send Payslip?</v-card-title>

                  <v-card-text>You are about to send {{ toSend }} payslip email(s). Proceed?</v-card-text>

                <v-card-actions>

                  <v-spacer></v-spacer>

                  <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                  >
                    No
                  </v-btn>
                  <!-- :disabled="toSend === 0" -->
                  <v-btn
                    color="green darken-1"
                    text
                    @click="send_payslip(directory.cntrl_no), dialog = false"
                  >
                    Yes
                  </v-btn>

                </v-card-actions>

              </v-card>

            </v-dialog>

          </v-row>

        </v-expansion-panel-content>

      </v-expansion-panel>

    </v-expansion-panels>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">

        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar= false"
        >

          Close

        </v-btn>

      </template>

    </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'Admin-Directory',
  data () {
    return {
      directories: [],
      search: '',
      grosspay: '',
      deduction: '',
      netpay: '',
      total_employee: '',
      toSend: 0,
      multiLine: true,
      snackbar: false,
      snackbarText: '',
      dialog: false,
      btn_disabled: false,
      show_app_bar: false,
      system_bar: true
    }
  },
  computed: {
    color () {
      return this.$vuetify.theme.dark ? '#121212' : 'white'
    }
  },
  methods: {
    payroll_directories () {
      axios.get('u/payroll/directories', {
        params: {
          vli_payr_grp: this.$route.params.id
        }
      })
        .then(response => {
          this.directories = response.data
          this.show_app_bar = true
        })
    },
    accept () {
      localStorage.setItem('pd_update_0', true)
      this.system_bar = false
    },
    formatNumber (num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },
    count_tosend_status (id) {
      axios.get('u/payroll/header/status/tosend', {
        params: {
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.toSend = response.data
          this.dialog = true
        })
    },
    send_payslip (id) {
      // axios.get('u/payroll/send/payslip', {
      //   params: {
      //     vli_payr_dir: id
      //   }
      // })
      axios({
        url: 'u/payslip/send',
        method: 'POST',
        data: {
          type: 'bulk',
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.snackbar = true
          this.snackbarText = 'Payslip has been queued.'
          this.btn_disabled = true
        })
    },
    retrieve_other_info (id) {
      this.compute_header_total_gross(id)
      this.compute_header_total_deduction(id)
      this.compute_header_total_net_pay(id)
      this.count_employees(id)
    },
    // vuex
    count_employees (id) {
      axios.get('u/payroll/directories/total-employee', {
        params: {
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.total_employee = response.data
        })
    },
    compute_header_total_gross (id) {
      axios.get('u/payroll/directories/total-gross-amount', {
        params: {
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.grosspay = response.data
        })
    },
    compute_header_total_deduction (id) {
      axios.get('u/payroll/directories/total-deduction', {
        params: {
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.deduction = response.data
        })
    },
    compute_header_total_net_pay (id) {
      axios.get('u/payroll/directories/total-net-pay', {
        params: {
          vli_payr_dir: id
        }
      })
        .then(response => {
          this.netpay = response.data
        })
    },
    searchPayrollDirectory () {
      if (this.search === '') {
        return this.payroll_directories()
      }
      axios.get('u/payroll/directory/search', {
        params: {
          payr_dir: this.search
        }
      })
        .then(response => {
          this.directories = response.data
          this.show_app_bar = true
        })
    }
  },
  created () {
    this.payroll_directories()
    if (localStorage.getItem('pd_update_0')) {
      this.system_bar = false
    }
  }
}
</script>

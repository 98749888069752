<template>
  <div>

    <Debounce></Debounce>

  </div>
</template>

<script>
import Debounce from '@/components/System/Validator/Debounce/Validator.vue'

export default {
  name: 'View-Validator-Debounce-Validator',
  components: {
    Debounce
  }
}
</script>

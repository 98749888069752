<template>

  <div>

    <Pending></Pending>

  </div>
</template>

<script>
import Pending from '@/components/Admin/Email/Pending.vue'

export default {
  name: 'View-Admin-Email-Pending',
  components: {
    Pending
  },
  data () {
    return {

    }
  }
}
</script>

<template>

  <div>

    <v-row>

      <v-col
        cols="12"
      >

        <v-data-table
          v-model="validate"
          :headers="headers"
          :items="emails"
          :search="search"
          :items-per-page="20"
          :loading="loading"
          item-key="cntrl_no"
          show-select
          class="elevation-1"
          height="250px"
        >

          <template v-slot:top>

            <v-card-title>

              <span>Mailing List</span>

              <v-spacer></v-spacer>

              <v-dialog
                v-model="dialog"
                persistent
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                    @click="dialog = true"
                    :disabled="bounce_upload_btn"
                    v-bind="attrs"
                    v-on="on"
                    large
                    icon
                  >

                    <v-icon>mdi-account-remove-outline</v-icon>

                  </v-btn>

                </template>

                <v-card>

                  <v-card-title class="text-h5">

                    Proceed?

                  </v-card-title>

                    <v-card-text>Invalid Email</v-card-text>

                  <v-card-actions>

                    <v-spacer></v-spacer>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="dialog = false"
                    >

                      No

                    </v-btn>

                    <v-btn
                      color="green darken-1"
                      text
                      @click="debounce_invalid_email(), dialog = false"
                    >

                      Yes

                    </v-btn>

                  </v-card-actions>

                </v-card>

              </v-dialog>

              <v-tooltip bottom>

                <template v-slot:activator="{ on, attrs }">

                  <v-btn
                    @click="debounce_upload"
                    :disabled="bounce_upload_btn"
                    v-bind="attrs"
                    v-on="on"
                    large
                    icon
                  >

                    <v-icon>mdi-cloud-upload-outline</v-icon>

                  </v-btn>

                </template>

                <span>Upload</span>

              </v-tooltip>

              <v-col cols="4">

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  outlined
                  hide-details
                  dense
                />

              </v-col>

            </v-card-title>

          </template>

        </v-data-table>

      </v-col>

    </v-row>

    <v-row>

      <v-col
        cols="12"
      >

        <v-data-table
          :headers="headers_validator"
          :items="email_validator"
          :search="search_validator"
          :items-per-page="7"
          :loading="loading_validator"
          item-key="cntrl_no"
          class="elevation-1"
          height="250px"
        >

          <!-- check status -->
          <template v-slot:item.action="{ item }">

            <!-- refresh -->
            <v-btn
              v-if="item.status === 'queued' || item.status === 'processing' || item.status === 'preparing'"
              @click="check_debounce_status(item.provider_id)"
              icon
            >

              <v-icon>mdi-refresh</v-icon>

            </v-btn>

            <!-- extract report -->
            <v-btn
              v-if="item.status === 'completed'"
              @click="debounce_extract_report(item)"
              icon
            >

              <v-icon>mdi-database-import-outline</v-icon>

            </v-btn>

            <!-- process -->
            <v-btn
              v-if="item.status === 'extracted'"
              @click="debounce_translate(item)"
              icon
            >

              <v-icon>mdi-clipboard-check-outline</v-icon>

            </v-btn>

            <!-- validated -->
            <v-btn
              v-if="item.status === 'validated'"
              icon
            >

              <v-icon>mdi-lock-check-outline</v-icon>

            </v-btn>

          </template>

        </v-data-table>

      </v-col>

    </v-row>

    <!-- Snackbar -->
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
    >

      {{ snackbarText }}

      <template v-slot:action="{ attrs }">

        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar= false"
        >

          Close

        </v-btn>

      </template>

    </v-snackbar>

  </div>

</template>

<script>
import axios from 'axios'

export default {
  name: 'Email-Validation',
  data () {
    return {
      search: '',
      search_validator: '',
      debounce_status: '',
      snackbarText: '',
      dialog: false,
      multiLine: true,
      snackbar: false,
      loading: false,
      loading_validator: false,
      debounceStatus: '',
      validate: [],
      cntrl_no: [],
      emails: [],
      email_validator: [],
      headers: [
        {
          text: 'Control #',
          value: 'cntrl_no',
          sortable: true
        },
        { text: 'Email Address', value: 'email', sortable: true },
        { text: 'Company Name', value: 'co_name_', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true }
      ],
      headers_validator: [
        {
          text: '#',
          value: 'cntrl_no',
          sortable: true
        },
        // { text: 'File Name', value: 'filename', sortable: true },
        // { text: 'URL', value: 'file_url', sortable: true },
        { text: 'Debounce ID', value: 'provider_id', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'Download Link', value: 'download_link', sortable: true },
        { text: 'Created At', value: 'created_at', sortable: true },
        { text: 'Updated At', value: 'updated_at', sortable: true },
        { text: 'Action', value: 'action', sortable: false }
      ]
    }
  },
  computed: {
    bounce_upload_btn () {
      return this.validate.length === 0
    }
  },
  methods: {
    list () {
      this.loading = true
      axios.get('s/email/debounce/list')
        .then(response => {
          this.emails = response.data
          this.loading = false
        })
    },
    debounce_invalid_email () {
      this.snackbar = true
      this.snackbarText = 'Validating...'
      Object.keys(this.validate).forEach(item => {
        this.cntrl_no.push(this.validate[item].cntrl_no)
      })
      axios.post('s/email/validator/invalid/email', {
        cntrl_no: this.cntrl_no,
        provider: 1
      })
        .then(() => {
          this.validate = []
          this.cntrl_no = []
          this.snackbar = true
          this.snackbarText = 'Validated!'
          this.list()
          this.debounce_email_validator()
        })
    },
    debounce_upload () {
      this.snackbar = true
      this.snackbarText = 'Generating Text File...'
      Object.keys(this.validate).forEach(item => {
        this.cntrl_no.push(this.validate[item].cntrl_no)
      })
      axios.post('s/email/debounce/bulk/upload', {
        cntrl_no: this.cntrl_no
      })
        .then(() => {
          this.validate = []
          this.cntrl_no = []
          this.snackbar = true
          this.snackbarText = 'Done!'
          this.list()
          this.debounce_email_validator()
        })
    },
    debounce_email_validator () {
      axios.get('s/email/validator', {
        params: {
          provider: 1
        }
      })
        .then(response => {
          this.email_validator = response.data
        })
    },
    check_debounce_status (providerID) {
      this.snackbar = true
      this.snackbarText = 'Checking...'
      axios.get('s/email/debounce/status', {
        params: {
          list_id: providerID
        }
      })
        .then(response => {
          this.snackbar = true
          this.snackbarText = 'Updated'
          // this.debounce_status = response.data.status
          this.debounce_email_validator()
        })
    },
    debounce_extract_report (item) {
      this.snackbar = true
      this.snackbarText = 'Extracting...'
      axios.post('s/email/debounce/extract/report', {
        list_id: item.list_id,
        download_link: item.download_link
      })
        .then(() => {
          this.snackbar = true
          this.snackbarText = 'Extracted...'
          this.debounce_email_validator()
        })
    },
    debounce_translate (item) {
      this.snackbar = true
      this.snackbarText = 'Validating...'
      axios.post('s/email/validator/translate', {
        list_id: item.list_id,
        provider: 1
      })
        .then(() => {
          this.snackbar = true
          this.snackbarText = 'Validated..'
          this.debounce_email_validator()
        })
    }
  },
  created () {
    this.list()
    this.debounce_email_validator()
  }
}
</script>

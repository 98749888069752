var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.payroll,"search":_vm.search,"items-per-page":5,"loading":_vm.loading,"height":"400px"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-card-title',[(_vm.show_id)?_c('span',[_vm._v("Payroll Headers #"+_vm._s(_vm.payroll[0].vli_payr_dir)+" ("+_vm._s(_vm.payroll[0].payr_dir)+" - Part "+_vm._s(_vm.payroll[0].part____)+")")]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":""},on:{"click":_vm.payroll_header}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-refresh-outline ")])],1)]}}])},[_c('span',[_vm._v("Refresh")])]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card-subtitle',{staticClass:"mt-n7"},[(_vm.show_id)?_c('span',[_vm._v("Start Date: "+_vm._s(_vm.payroll[0].strt_dte)+" | End Date: "+_vm._s(_vm.payroll[0].last_dte)+" | Credit Date: "+_vm._s(_vm.payroll[0].credt_dt))]):_vm._e()])]},proxy:true},{key:"item.empl_cde",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_c('u',[_vm._v(_vm._s(item.empl_cde))])])]}}],null,true)},[_c('span',[_vm._v("System ID: "+_vm._s(item.vli_empl_mst))]),_c('br'),_c('span',[_vm._v("Email: "+_vm._s(item.email))]),_c('br'),_c('span',[_vm._v("Mobile: "+_vm._s(item.mobile__))]),_c('br'),_c('span',[_vm._v("PDF Pin: "+_vm._s(item.pdf_password))])])]}},{key:"item.grosspay",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.grosspay))+" ")]}},{key:"item.deductn_",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.deductn_))+" ")]}},{key:"item.net_pay_",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.net_pay_))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":{ name: 'Admin-MasterFile-Edit', params: { id: item.vli_empl_mst } },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-account-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("View Information")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.disable_download},on:{"click":function($event){return _vm.download_payslip(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Download Payslip")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.log_type === 125)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.send_payslip(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email-send-outline ")])],1):_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-file-send-outline ")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"290","retain-focus":_vm.retainFocus},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Resend Payslip?")]),_c('v-card-text',[_vm._v("You are about to send payslip email(s) to "+_vm._s(_vm.editedItem.last_nme)+", "+_vm._s(_vm.editedItem.frst_nme)+". Proceed?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.send_payslip(_vm.editedItem)}}},[_vm._v(" Yes ")])],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v("Send/Resend Payslip")])])]}}])}),_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar= false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>

  <div>

    <Settings></Settings>

  </div>
</template>

<script>
import Settings from '@/components/Settings/Settings.vue'

export default {
  name: 'View-Settings',
  components: {
    Settings
  },
  data () {
    return {

    }
  }
}
</script>

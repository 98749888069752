<template>

  <div>

    <Resent></Resent>

  </div>
</template>

<script>
import Resent from '@/components/Admin/Email/Resent.vue'

export default {
  name: 'View-Admin-Email-Resent',
  components: {
    Resent
  },
  data () {
    return {

    }
  }
}
</script>

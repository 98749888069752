<template>

  <div>

    <Regsiter></Regsiter>

  </div>

</template>
<script>
import Regsiter from '@/components/Authentication/User/Register.vue'

export default {
  name: 'View-Regsiter',
  components: {
    Regsiter
  }
}
</script>
